<template>
  <ion-page>
    <cyan-page-wrapper
      :title="title"
      :backRoute="backRoute ?? '/home'"
      :nav1="nav1 ?? title.toUpperCase()"
      :nav2="datosCentro.nombre"
      :ambito="ambito"
    >
      <div class="mobileShow">
        <div class="banner nivel1">{{ datosCentro.nombre }}</div>
        <div class="banner nivel2">{{ ambito }}</div>
      </div>

      <ion-select placeholder="Elegir JRV..." cancelText="Cancelar" interface="action-sheet" v-model="jrv">
        <ion-select-option v-for="id in listaJrv" :key="id" :value="id">
          {{ nombres[id] }}
        </ion-select-option>
      </ion-select>

      <transition name="cyanfadefast" mode="out-in">
        <div v-if="!jrv" class="banner nivel2 nojrv">
          {{ noJrv ?? "Escoja una JRV en el desplegable." }}
        </div>
        <div v-else :key="jrv">
          <slot :jrv="jrv" :updateSelect="updateSelect"></slot>
        </div>
      </transition>
    </cyan-page-wrapper>
  </ion-page>
</template>

<script lang="ts">
import CyanPageWrapper from "@/components/CyanPageWrapper.vue";
import { defineComponent } from "vue";
import { IonPage, IonSelect, IonSelectOption } from "@ionic/vue";

import {
  attach,
  imagesOutline,
  alertCircleOutline,
  checkmarkCircleOutline,
} from "ionicons/icons";

import { municipios } from "@/modules/cyanRegions";
import ud from "@/modules/cyanUserData";

export default defineComponent({
  name: "JrvPickStreamlined",
  components: {
    IonPage,
    CyanPageWrapper,
    IonSelect,
    IonSelectOption,
  },
  setup() {
    return {
      attach,
      imagesOutline,
      alertCircleOutline,
      checkmarkCircleOutline,
    };
  },
  data() {
    return {
      jrv: "",
    };
  },
  computed: {
    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in municipios)) return "";
      return municipios[n].nombre + ", " + municipios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },

    incidencias(): any {
      return ud("incidencias", []);
    },
    listaJrv(): any {
      return ud("jrv");
    },
    nombres(): any {
      return this.nombreJrv ?? ud("nombreJrv");
    }
  },
  methods: {
    updateSelect() {
      const _t = this as any;

          const e = document.querySelector("ion-select");
          if (!e) return;

          e.setAttribute('disabled',"1");
          e.removeAttribute('disabled');
    },

  },
  props: {
    title: String,
    backRoute: String,
    nav1: {
      type: String,
    },
    noJrv: String,
    nombreJrv: Object,
  },
});
</script>

<style scoped>

ion-select {
  margin: 2em 0;
  padding: 10px ;
  text-align: center;
  border-radius: 5px;
  background: rgb(179, 233, 229);
}
</style>